import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Footer from "./Footer";
import Navbar from "./navbar";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import { useAlerterContext } from "./Context/AlerterContext";
import { useHistory } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import Aos from "aos";

const FeedbackSchema = Yup.object().shape({
  parlour: Yup.string().required("Parlour selection is required"),
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be at most 50 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .test("is-valid-phone", "Invalid phone number format", (value) => {
      const phoneNumberPattern = /(?:\+?\d{1,3})?[-.\s]?(\d{10})/;
      return phoneNumberPattern.test(value);
    }),
  feedback: Yup.string()
    .max(500, "Feedback must be at most 500 characters")
    .required("Share your valuable feedback"),
  // talkAbout: Yup.string().required("Please select a topic to talk about"),
  ratings: Yup.object().shape({
    overallExperience: Yup.number()
      .min(0.5, "Please rate your experience")
      .max(5, "Rating must be at most 5")
      .required("Please rate your experience"),
    quality: Yup.number()
      .min(0.5, "Please rate quality")
      .max(5, "Rating must be at most 5")
      .required("Please rate quality"),
    staffBehaviour: Yup.number()
      .min(0.5, "Please rate staff behaviour")
      .max(5, "Rating must be at most 5")
      .required("Please rate staff behaviour"),
    hygiene: Yup.number()
      .min(0.5, "Please rate hygiene")
      .max(5, "Rating must be at most 5")
      .required("Please rate hygiene"),
  }),
});

const FeedbackForm = () => {
  const [parlours, setParlours] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const { showAlert } = useAlerterContext();
  const history = useHistory();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  // Effect to show validation errors in alerter
  useEffect(() => {
    if (validationErrors.length > 0) {
      // Combine all validation error messages
      const errorMessage = validationErrors.join(". ");

      // Show the combined error message
      showAlert(errorMessage, "error");

      // Clear the errors after showing
      setValidationErrors([]);
    }
  }, [validationErrors, showAlert]);

  // Fetch parlours on component mount
  useEffect(() => {
    const fetchParlours = async () => {
      try {
        const response = await axios.get(
          "https://redcowdairy.in/api/fetch_locator_data.php"
        );
        const parloursList = Object.keys(response.data).flatMap((city) =>
          response.data[city].map((parlour, index) => ({
            id: `${city}-${index}`,
            name: parlour.tabData.storeName,
          }))
        );
        setParlours(parloursList);
      } catch (error) {
        showAlert("Failed to load parlours. Please try again later.", "error");
      }
    };

    fetchParlours();
  }, [showAlert]);

  const extractPhoneNumber = (input) => {
    const phoneNumberPattern = /(?:\+?\d{1,3})?[-.\s]?(\d{10})/;
    const match = input.match(phoneNumberPattern);
    return match ? match[1] : "";
  };

  // Form submission handler
  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, validateForm }
  ) => {
    try {
      console.log("from input values", values);
      const errors = await validateForm(); // Manually trigger validation
      if (Object.keys(errors).length > 0) {
        setValidationErrors(Object.values(errors).flat()); // Set validation errors
        setSubmitting(false);
        return;
      }

      // Extract phone number
      const extractedPhone = extractPhoneNumber(values.phone);
      const { parlour, name, email, phone, feedback, ratings } = values;
      const { overallExperience, quality, staffBehaviour, hygiene } = ratings;

      const formData = {
        parlour,
        name,
        email,
        phone: extractedPhone,
        feedback,
        overallExperience,
        quality,
        staffBehaviour,
        hygiene,
      };

      const response = await axios.post(
        "https://triverseadvertising.com/redcow/api/insert_feedback_form.php",
        formData
      );

      const resData = response.data;
      console.log(response.data);

      if (resData.status === "success") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "formSubmission",
          formName: "FeedbackForm",
          formValues: values,
        });

        history.push("/thank-you");
        resetForm();
      } else {
        showAlert("Failed to submit feedback. Please try again.", "error");
      }
    } catch (error) {
      showAlert(
        "An error occurred during submission. Please try again.",
        "error"
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Navbar />
      <Helmet>
        <title>{"Feedback"}</title>
        <meta
          name="description"
          content={"Share your experience and help us improve at Red Cow Dairy"}
        />
      </Helmet>
      <section id="forms">
        <div className="forms feedback_form py-5 py-m-0 ">
          <div className="container">
            <div className="row">
              <div className="web-container">
                <div className="heading">
                  <h1>Feedback Form</h1>
                </div>

                <Formik
                  initialValues={{
                    parlour: "",
                    name: "",
                    email: "",
                    phone: "",
                    feedback: "",
                    // talkAbout: "",
                    ratings: {
                      overallExperience: 0,
                      quality: 0,
                      staffBehaviour: 0,
                      hygiene: 0,
                    },
                  }}
                  validationSchema={FeedbackSchema}
                  validateOnChange={false}
                  validateOnBlur={true}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    setFieldValue,
                    setFieldTouched,
                    validateForm,
                  }) => (
                    <Form className="">
                      <ul>
                        <li className="w-100">
                          <label className="block mb-2">
                            Ice Cream Parlour Location*
                          </label>
                          <Field
                            as="select"
                            name="parlour"
                            className={`form-Feilds ${
                              errors.parlour && touched.parlour ? "error" : ""
                            }`}
                          >
                            <option value="" disabled selected>
                              Choose Parlour
                            </option>
                            {parlours && parlours.length > 0 ? (
                              parlours.map((parlour) => (
                                <option key={parlour.id} value={parlour.name}>
                                  {parlour.name}
                                </option>
                              ))
                            ) : (
                              <option disabled>Loading parlours...</option>
                            )}
                          </Field>
                        </li>

                        {/* Name */}
                        <li className="w_30 ">
                          <label className="block mb-2">Your Name*</label>
                          <Field
                            type="text"
                            name="name"
                            placeholder="e.g., Aarav Sharma"
                            className={`form-Feilds ${
                              errors.name && touched.name ? "error" : ""
                            }`}
                          />
                        </li>

                        {/* Email */}
                        <li className="w_30">
                          <label className="block mb-2">
                            Your Email Address*
                          </label>
                          <Field
                            type="email"
                            name="email"
                            placeholder="e.g., aarav.sharma@example.com"
                            className={`form-Feilds ${
                              errors.email && touched.email ? "error" : ""
                            }`}
                          />
                        </li>

                        {/* Phone */}
                        <li className="w_30">
                          <label className="block mb-2">
                            Your Contact Number*
                          </label>
                          <Field
                            type="tel"
                            name="phone"
                            placeholder="e.g., 98765 43210"
                            className={`form-Feilds ${
                              errors.phone && touched.phone ? "error" : ""
                            }`}
                          />
                        </li>
                        {[
                          "overallExperience",
                          "quality",
                          "staffBehaviour",
                          "hygiene",
                        ].map((field, index) => (
                          <li key={index} className="w_50">
                            <label className="block mb-2">
                              {field === "overallExperience"
                                ? "Overall Experience"
                                : field === "quality"
                                ? "Quality of Ice Cream"
                                : field === "staffBehaviour"
                                ? "Staff Behaviour"
                                : "Hygiene"}{" "}
                              *
                            </label>
                            <div className="flex items-center">
                              <Rating
                                size={50}
                                transition
                                allowFraction
                                fillColor={"#ffc857"}
                                onClick={(rate) => {
                                  setFieldValue(`ratings.${field}`, rate);
                                  setTimeout(
                                    () =>
                                      setFieldTouched(`ratings.${field}`, true),
                                    100
                                  );
                                }}
                                initialValue={values.ratings[field] || 0}
                              />
                              {errors.ratings?.[field] &&
                                touched.ratings?.[field] && (
                                  <p className="error">
                                    {errors.ratings[field]}
                                  </p>
                                )}
                            </div>
                          </li>
                        ))}

                        {/* Feedback */}
                        <li className="w-100">
                          <label className="block mb-2">
                            Share your experience that influenced your rating*
                          </label>
                          <Field
                            as="textarea"
                            name="feedback"
                            placeholder="Let us know about your favorite flavours or how we can improve your experience!"
                            maxLength="500"
                            className={`w-100 form-Feilds ${
                              errors.feedback && touched.feedback ? "error" : ""
                            }`}
                          />
                        </li>
                        <li>
                          <input
                            type="submit"
                            value={isSubmitting ? "Submitting..." : "Submit"}
                            disabled={isSubmitting}
                            onClick={async () => {
                              const formErrors = await validateForm();
                              const errorCount = Object.keys(formErrors).length;

                              if (errorCount > 2) {
                                showAlert(
                                  "Please complete all required fields.",
                                  "error"
                                );
                              } else if (errorCount > 0) {
                                const errorMessages = Object.values(formErrors)
                                  .map((error) => {
                                    if (
                                      error &&
                                      typeof error === "object" &&
                                      !Array.isArray(error)
                                    ) {
                                      // Extract only the values from the error object.
                                      return Object.values(error).join(", ");
                                    }
                                    return error;
                                  })
                                  .join(", ");

                                showAlert(errorMessages, "error");
                              }

                              console.log("this is the error", formErrors);
                            }}
                          />
                        </li>
                      </ul>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FeedbackForm;
