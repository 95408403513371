import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";

// Create context
const AlerterContext = createContext();

// Custom hook to use AlerterContext
export const useAlerterContext = () => {
  return useContext(AlerterContext);
};

// Alerter Provider
export const AlerterProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    type: "info",
  });

  // Show alert and auto-close after 5 seconds
  const showAlert = useCallback((message, type = "info") => {
    setAlert({ open: true, message, type });

    // Auto-hide after 5 seconds
    const timer = setTimeout(() => {
      setAlert({ open: false, message: "", type: "info" });
    }, 5000);

    // Return a function to clear the timer if needed
    return () => clearTimeout(timer);
  }, []);

  const closeAlert = useCallback(() => {
    setAlert({ open: false, message: "", type: "info" });
  }, []);

  return (
    <AlerterContext.Provider value={{ showAlert, closeAlert }}>
      {children}
      {alert.open && (
        <div className={`professional-alert alert-${alert.type}`}>
          <div className="alert-content">
            <div className="alert-icon">
              {alert.type === "success" && "✓"}
              {alert.type === "error" && "!"}
              {alert.type === "info" && "ℹ"}
            </div>
            <div className="alert-text">
              <div className="alert-type">{alert.type}</div>
              <div className="alert-message">{alert.message}</div>
            </div>
            <button className="alert-close" onClick={closeAlert}>
              &times;
            </button>
          </div>
        </div>
      )}
    </AlerterContext.Provider>
  );
};

export default AlerterProvider;
